<template>
  <div>
    <div class="card-body">
        <div class="row">

          <div class="d-flex w-100 align-items-end mb-1">

            <div class="w-100 mr-1">
              <input v-model="currentSetting.value" type="text" class="form-control" id="defaultAdminPanelUrl" placeholder="Admin Panel Url">
            </div>

            <div>
              <button :disabled="isUpdateButtonDisable" @click="updateSettingsHandler" class="btn btn-primary px-lg-2" type="submit">Update</button>
            </div>

          </div>

        </div>
      </div>
    </div>
</template>

<script>

import {mapActions} from "vuex";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import Authorization from "@/components/backEnd/mixins/Authorization";


export default {
  name: "SettingDefaultAdminPanelUrl",
  mixins: [ShowToastMessage, Loader, Authorization],
  props: ['defaultAdminPanelUrl'],
  data() {
    return {
      currentSetting: this.defaultAdminPanelUrl,
    };
  },
  computed: {
    isUpdateButtonDisable() {
      return this.currentSetting.value === '' || this.currentSetting.value === null || !this.can('setting-default-update');
    }
  },
  methods: {
    ...mapActions({
      putSetting: "appSettings/putSetting",
    }),

    async updateSettingsHandler() {
      await this.updateSettings();
    },

    async updateSettings() {
      let dataObj = {
        id: this.currentSetting.id,
        data: {
          type: this.currentSetting.type,
          key: this.currentSetting.key,
          value: this.currentSetting.value,
        }
      };
      await this.loader(true);
      await this.putSetting(dataObj).then(async (response) => {
        await this.loader(false);

        if (response.status === 200) {
          const toastObj = {
            message: 'Admin panel url updated.',
            type: 'success'
          };

          this.showToastMessage(toastObj);
        }
        this.showToastMessage(response);
      });
    }
  },
}
</script>

<style scoped>

</style>