<template>
  <div>
    <div class="card-body">

        <div class="row">
          <div class="table-responsive">
            <table id="users-list-datatable" class="table">
              <thead>
              <tr>
                <th class="position-relative" style="width: 5%">Value</th>
                <th class="position-relative" style="width: 20%">Name</th>
              </tr>
              </thead>
              <tbody>

              <tr v-for="(parkingType,index) in settingDefaultParkingType?.value ?? []" :key="index">
                <td>{{ parkingType.value }}</td>
                <td>{{ parkingType.name }}</td>

              </tr>
              </tbody>
            </table>
          </div>

          <div class="col-12">
            <div class="text-danger"><span>*For update parking type, please contact with system Webmaster.</span></div>
          </div>

        </div>

      </div>
    </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";


export default {
  name: "SettingDefaultParkingType",
  mixins: [ShowToastMessage, Loader],
  props: ['defaultParkingType'],
  data() {
    return {
      currentSetting: this.defaultParkingType,
      parkingType: [],
    };
  },
  watch: {},
  computed: {
    ...mapGetters({
      settingDefaultParkingType: 'appSettings/settingDefaultParkingType',

    }),
  },
  methods: {
    ...mapActions({
      putSetting: "appSettings/putSetting",
    }),
  },
}
</script>

<style scoped>

</style>