<template>
  <div>
        <div class="card-body">
          <div class="row">

            <div class="col-md-6">
              <div>
                <h5 class="py-1 text-center">Write your template </h5>
                <div>
                  <prism-editor class="my-editor" v-model="currentSetting.value" :highlight="highlighter" line-numbers></prism-editor>
                  <div class="mt-1 d-flex align-items-center">
                    <i class='bx bx-info-circle'></i>&nbsp;

                    <span>{{ currentSetting.description }}</span>
                  </div>
                </div>

                <div class="py-3 d-flex justify-content-end">
                  <button :disabled="isUpdateButtonDisable" @click="updateSettingsHandler" class="btn btn-primary px-lg-2" type="submit">Update</button>
                </div>
              </div>

            </div>

            <div class="col-md-6 " v-if="currentSetting.value.length">
              <h5 class="py-1 text-center">Template Preview </h5>
              <div style="height: auto;" class="py-2">
                <div v-html="currentSetting.value">
                </div>
              </div>
            </div>
          </div>
        </div>
  </div>
</template>
<script>
import {
  PrismEditor
} from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css'; // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import {
  highlight,
  languages
} from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism-tomorrow.css'; // import syntax highlighting styles

import {mapActions} from "vuex";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import Authorization from "@/components/backEnd/mixins/Authorization";


export default {
  name: "SettingDefaultInternalEmailHeader",
  mixins: [ShowToastMessage, Loader, Authorization],
  props: ['defaultInternalEmailHeader'],
  components: {
    PrismEditor,
  },
  data() {
    return {

      currentSetting: this.defaultInternalEmailHeader,
    };
  },
  computed: {
    isUpdateButtonDisable() {
      return this.currentSetting.value === '' || this.currentSetting.value === null || !this.can('setting-default-update');
    }
  },
  methods: {
    ...mapActions({
      putSetting: "appSettings/putSetting",
    }),

    highlighter(code) {
      return highlight(code, languages.js); // languages.<insert language> to return html with markup
    },

    async updateSettingsHandler() {
      await this.updateSettings();
    },

    async updateSettings() {
      let dataObj = {
        id: this.currentSetting.id,
        data: {
          type: this.currentSetting.type,
          key: this.currentSetting.key,
          value: this.currentSetting.value,
        }
      };
      await this.loader(true);
      await this.putSetting(dataObj).then(async (response) => {
        await this.loader(false);

        if (response.status === 200) {
          const toastObj = {
            message: 'Internal email header updated.',
            type: 'success'
          };

          this.showToastMessage(toastObj);
        }
        this.showToastMessage(response);
      });
    }
  },
}
</script>

<style>
.my-editor {
  background: #2d2d2d;
  color: white !important;

  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
}

</style>