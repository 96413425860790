<template>
  <AppLayout>
    <template v-slot:appContent>
      <div>
      <div class="system-detail-setting-wrapper">
        <div class="d-sm-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0">
          <h4 class="">Default System Details/List</h4>
        </div>
            <div class="accordion" id="systemDetailsAccordion">

              <div class="card">
                <div class="card-header" id="settingDefaultAdminPanelUrlHeading">
                  <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                          data-target="#settingDefaultAdminPanelUrlCollapse" aria-expanded="false" aria-controls="collapseAdminPanelUrl">

                    <span class="text-capitalize system-detail-setting-title"> Admin Panel Url</span>

                  </button>
                </div>
                <div id="settingDefaultAdminPanelUrlCollapse" class="collapse" aria-labelledby="settingDefaultAdminPanelUrlHeading" data-parent="#systemDetailsAccordion">
                  <div class="card-body">
                    <SettingDefaultAdminPanelUrl :default-admin-panel-url="this.defaultAdminPanelUrl"/>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header" id="settingDefaultStateHeading">
                  <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                          data-target="#settingDefaultStateCollapse" aria-expanded="false" aria-controls="collapseState">

                    <span class="text-capitalize system-detail-setting-title"> State</span>

                  </button>
                </div>
                <div id="settingDefaultStateCollapse" class="collapse" aria-labelledby="settingDefaultStateHeading" data-parent="#systemDetailsAccordion">
                  <div class="card-body">
                    <SettingDefaultState v-if="this.defaultState?.value?.length > 0" :default-state="this.defaultState"/>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header" id="settingDefaultStateTimezoneHeading">
                  <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                          data-target="#settingDefaultStateTimezoneCollapse" aria-expanded="false" aria-controls="collapseStateTimezone">

                    <span class="text-capitalize system-detail-setting-title"> State Timezone</span>

                  </button>
                </div>
                <div id="settingDefaultStateTimezoneCollapse" class="collapse" aria-labelledby="settingDefaultStateTimezoneHeading" data-parent="#systemDetailsAccordion">
                  <div class="card-body">
                    <SettingDefaultStateTimezone :default-state-timezone="this.defaultStateTimezone"/>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header" id="settingDefaultParkingTypeHeading">
                  <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                          data-target="#settingDefaultParkingTypeCollapse" aria-expanded="false" aria-controls="collapseDefaultParkingType">

                    <span class="text-capitalize system-detail-setting-title"> Parking Type</span>

                  </button>
                </div>
                <div id="settingDefaultParkingTypeCollapse" class="collapse" aria-labelledby="settingDefaultParkingTypeHeading" data-parent="#systemDetailsAccordion">
                  <div class="card-body">
                    <SettingDefaultParkingType :default-parking-type="this.defaultParkingType"/>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header" id="settingDefaultCustomerTypeHeading">
                  <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                          data-target="#settingDefaultCustomerTypeCollapse" aria-expanded="false" aria-controls="collapseDefaultCustomerType">

                    <span class="text-capitalize system-detail-setting-title">  Customer Type</span>

                  </button>
                </div>
                <div id="settingDefaultCustomerTypeCollapse" class="collapse" aria-labelledby="settingDefaultCustomerTypeHeading" data-parent="#systemDetailsAccordion">
                  <div class="card-body">
                    <SettingDefaultCustomerType :default-customer-type="this.defaultCustomerType"/>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header" id="settingDefaultCustomerRoleHeading">
                  <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                          data-target="#settingDefaultCustomerRoleCollapse" aria-expanded="false" aria-controls="collapseDefaultCustomerRole">

                    <span class="text-capitalize system-detail-setting-title">  Customer Role</span>

                  </button>
                </div>
                <div id="settingDefaultCustomerRoleCollapse" class="collapse" aria-labelledby="settingDefaultCustomerRoleHeading" data-parent="#systemDetailsAccordion">
                  <div class="card-body">
                    <SettingDefaultCustomerRole v-if="Object.keys(this.defaultCustomerRole?.value ?? {}).length > 0" :default-customer-role="this.defaultCustomerRole"/>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header" id="settingDefaultPaginationHeading">
                  <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                          data-target="#settingDefaultPaginationCollapse" aria-expanded="false" aria-controls="collapseDefaultCustomerRole">

                    <span class="text-capitalize system-detail-setting-title"> Pagination</span>

                  </button>
                </div>
                <div id="settingDefaultPaginationCollapse" class="collapse" aria-labelledby="settingDefaultPaginationHeading" data-parent="#systemDetailsAccordion">
                  <div class="card-body">
                    <SettingDefaultPagination :default-pagination="this.defaultPagination"/>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header" id="settingDefaultInternalEmailHeaderHeading">
                  <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                          data-target="#settingDefaultInternalEmailHeaderCollapse" aria-expanded="false" aria-controls="collapseDefaultInternalEmailHeader">

                    <span class="text-capitalize system-detail-setting-title"> Internal Email Header</span>

                  </button>
                </div>
                <div id="settingDefaultInternalEmailHeaderCollapse" class="collapse" aria-labelledby="settingDefaultInternalEmailHeaderHeading" data-parent="#systemDetailsAccordion">
                  <div class="card-body">
                    <SettingDefaultInternalEmailHeader :default-internal-email-header="this.defaultInternalEmailHeader"/>
                  </div>
                </div>
              </div>


              <div class="card">
                <div class="card-header" id="settingDefaultInternalEmailFooterHeading">
                  <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                          data-target="#settingDefaultInternalEmailFooterCollapse" aria-expanded="false" aria-controls="collapseDefaultInternalEmailFooter">

                    <span class="text-capitalize system-detail-setting-title"> Internal Email Footer</span>

                  </button>
                </div>
                <div id="settingDefaultInternalEmailFooterCollapse" class="collapse" aria-labelledby="settingDefaultInternalEmailFooterHeading" data-parent="#systemDetailsAccordion">
                  <div class="card-body">
                    <SettingDefaultInternalEmailFooter :default-internal-email-footer="this.defaultInternalEmailFooter"/>
                  </div>
                </div>
              </div>

            </div>

      </div>
      </div>
    </template>
  </AppLayout>
</template>

<script>
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import AppLayout from "@/layouts/backEnd/AppLayout";
import {mapActions, mapGetters} from "vuex";
import SettingDefaultAdminPanelUrl from "@/views/backEnd/settings/default/systemDetails/includes/SettingDefaultAdminPanelUrl";
import SettingDefaultState from "@/views/backEnd/settings/default/systemDetails/includes/SettingDefaultState";
import SettingDefaultStateTimezone from "@/views/backEnd/settings/default/systemDetails/includes/SettingDefaultStateTimezone";
import SettingDefaultPagination from "@/views/backEnd/settings/default/systemDetails/includes/SettingDefaultPagination";
import SettingDefaultParkingType from "@/views/backEnd/settings/default/systemDetails/includes/SettingDefaultParkingType";
import SettingDefaultCustomerType from "@/views/backEnd/settings/default/systemDetails/includes/SettingDefaultCustomerType";
import SettingDefaultCustomerRole from "@/views/backEnd/settings/default/systemDetails/includes/SettingDefaultCustomerRole";
import SettingDefaultInternalEmailHeader from "@/views/backEnd/settings/default/systemDetails/includes/SettingDefaultInternalEmailHeader";
import SettingDefaultInternalEmailFooter from "@/views/backEnd/settings/default/systemDetails/includes/SettingDefaultInternalEmailFooter";

export default {
  name: "SettingDefaultSystemDetails.vue",
  mixins: [ShowToastMessage, Loader],
  components: {
    AppLayout,
    SettingDefaultAdminPanelUrl,
    SettingDefaultState,
    SettingDefaultStateTimezone,
    SettingDefaultPagination,
    SettingDefaultParkingType,
    SettingDefaultCustomerType,
    SettingDefaultCustomerRole,
    SettingDefaultInternalEmailHeader,
    SettingDefaultInternalEmailFooter,

  },
  data() {
    return {
      getSettingsParams: {
        type: ["default"],
        key: [
          "default_admin_panel_url",
          "default_state_timezone",
          "default_parking_type",
          "default_pagination",
          "default_state",
          "default_customer_type",
          "default_customer_role",
          "default_internal_email_header",
          "default_internal_email_footer",

        ],
      },
    }
  },
  computed: {
    ...mapGetters({
      defaultAdminPanelUrl: 'appSettings/settingDefaultAdminPanelUrl',
      defaultState: 'appSettings/settingDefaultState',
      defaultStateTimezone: 'appSettings/settingDefaultStateTimezone',
      defaultParkingType: 'appSettings/settingDefaultParkingType',
      defaultCustomerType: 'appSettings/settingDefaultCustomerType',
      defaultPagination: 'appSettings/settingDefaultPagination',
      defaultCustomerRole: 'appSettings/settingDefaultCustomerRole',
      defaultInternalEmailHeader: 'appSettings/settingDefaultInternalEmailHeader',
      defaultInternalEmailFooter: 'appSettings/settingDefaultInternalEmailFooter',
    }),
  },
  methods: {
    ...mapActions({
      getSettings: "appSettings/getSettings",
    }),
    async getSettingList() {
      await this.getSettings(this.getSettingsParams);
    },
  },
  async mounted() {
    await this.loader(true);
    await this.getSettingList();
    await this.loader(false);
  },

}
</script>

<style>
.system-detail-setting-wrapper .accordion .card {
  overflow: visible !important;
}

.system-detail-setting-title {
  font-size: 16px;
  color: rgb(44, 44, 44);
}
</style>
