<template>
  <div>
    <div class="card-body">

        <div class="row">
          <div class="table-responsive">
            <table id="users-list-datatable" class="table">
              <thead>
              <tr>
                <th class="position-relative" style="width: 5%">Value</th>
                <th class="position-relative" style="width: 20%">Name</th>
              </tr>
              </thead>
              <tbody>

              <tr v-for="(customerType,index) in settingDefaultCustomerType?.value ?? []" :key="index">
                <td>{{ customerType.value }}</td>
                <td>{{ customerType.name }}</td>

              </tr>
              </tbody>
            </table>
          </div>

          <div class="col-12">
            <div class="text-danger"><span>*For update customer type, please contact with system Webmaster.</span></div>
          </div>

        </div>

      </div>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";

export default {
  name: "SettingDefaultCustomerType",
  mixins: [ShowToastMessage, Loader],
  props: ['defaultCustomerType'],
  data() {
    return {
      currentSetting: this.defaultCustomerType,
      customerType: [],
    };
  },
  computed: {
    ...mapGetters({
      settingDefaultCustomerType: 'appSettings/settingDefaultCustomerType',
    }),
  },
  methods: {
    ...mapActions({
      putSetting: "appSettings/putSetting",
    }),
  },
}
</script>

<style scoped>

</style>