<template>
  <div>
    <div class="card-body">
        <div class="row">
          <div class="col-md-4">
            <VueMultiselect v-model="selectedRole" class="multiselect-blue" :options="customerRoleList"
                            :close-on-select="true" :allow-empty="false" placeholder="Select Role"
                            label="name" track-by="value" :show-labels="false"/>
          </div>
          <div class="col-md-2">
            <button :disabled="isUpdateButtonDisable" class="btn btn-primary" @click="updateSettingsHandler" type="submit">Update</button>
          </div>
        </div>
      </div>
    </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import Authorization from "@/components/backEnd/mixins/Authorization";


export default {
  name: "SettingDefaultCustomerRole",
  mixins: [ShowToastMessage, Loader, Authorization],
  props: ['defaultCustomerRole'],
  data() {
    return {
      selectedRole: "",
      currentSetting: this.defaultCustomerRole,
      customerRoleList: [],
    };
  },
  computed: {
    ...mapGetters({
      roles: 'appRoles/roles',
    }),

    isUpdateButtonDisable() {
      return this.currentSetting.value === '' || this.currentSetting.value === null || !this.can('setting-default-update');
    }
  },
  methods: {
    ...mapActions({
      putSetting: "appSettings/putSetting",
      getRoles: 'appRoles/getRoles',
    }),

    async getRoleList(rolesParams) {
      this.loader(true);
      await this.getRoles(rolesParams);
      this.loader(false);

      this.customerRoleList = this.roles.map((roleObj) => {
        let roleId = roleObj.id;
        let roleName = roleObj.name;

        return {
          value: roleId,
          name: roleName
        };
      });
      let previousSelectedDefaultCustomer = this.customerRoleList.find((role) => role.value === this.defaultCustomerRole.value?.value);

      if (previousSelectedDefaultCustomer) {
        this.selectedRole = previousSelectedDefaultCustomer;
      }

    },
    async updateSettingsHandler() {
      if (this.selectedRole?.value) {
        await this.updateSettings();
        return;
      }
      this.showToastMessage({
        type: 'error',
        message: 'Please select a role !'
      })

    },

    async updateSettings() {
      let dataObj = {
        id: this.currentSetting.id,
        data: {
          type: this.currentSetting.type,
          key: this.currentSetting.key,
          value: {
            value: this.selectedRole.value,
            name: this.selectedRole.name
          },
        }
      };
      await this.loader(true);
      await this.putSetting(dataObj).then(async (response) => {
        await this.loader(false);

        if (response.status === 200) {
          const toastObj = {
            message: 'Customer role updated.',
            type: 'success'
          };

          this.showToastMessage(toastObj);
        }
        this.showToastMessage(response);
      });
    },
  },
  async mounted() {
    await this.getRoleList({type: 0});
  },
}
</script>

<style scoped>

</style>